.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: max-content;
  max-width: 300px;
  background-color: #fff;
  color: #29315d;
  border: 1px solid #f1f4f8;
  text-align: center;
  font-weight: 500;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-family: "Inter", sans-serif;
}

/* Default Tooltip Position (Top) */
.tooltip.top .tooltip-text {
  bottom: 105%;
  left: 50%;
  transform: translateX(-50%);
}

/* Tooltip Positioned at the Bottom */
.tooltip.bottom .tooltip-text {
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
}

/* Tooltip Positioned to the Left */
.tooltip.left .tooltip-text {
  top: 50%;
  right: 105%;
  transform: translateY(-50%);
}

/* Tooltip Positioned to the Right */
.tooltip.right .tooltip-text {
  top: 50%;
  left: 105%;
  transform: translateY(-50%);
}

/* Hover Effect to Show Tooltip */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
