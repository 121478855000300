.tableContainer {
  overflow: auto;
}

.tableContainer table {
  width: -webkit-fill-available;
  margin: 0 24px;
  border-collapse: separate;
  border-spacing: 0px 3px;
}

.tableContainer th {
  color: #677084;
  font-family: "Inter";
  text-align: left;
  padding: 16px;
}

.tableContainer tbody tr {
  background: #ffffff;
}

.tableContainer td {
  padding: 16px;
  font-family: "Inter";
}

.tableContainer td .typeColumn {
  color: #828b9d;
}

.tableContainer td .userCell {
  color: #f75e6e;
  border: 1px solid #f75e6e;
  border-radius: 50%;
  width: max-content;
  padding: 6px;
  font-size: 12px;
  display: initial;
}

.tableContainer td:first-child {
  border-radius: 10px 0 0 10px;
}
.tableContainer td:last-child {
  border-radius: 0 10px 10px 0;
}

.pagination {
  color: #677084;
  position: fixed;
  bottom: 0;
  margin: 24px 0px 10px 0px;
  font-family: "Inter";
  left: 105px;
  right: 48px;
}

.pageSizeDropdown {
  border: none;
  background-color: white;
  border-radius: 6px;
  height: 30px;
  font-family: "Inter";
  font-size: 14px;
  outline: none;
}

.pagination button {
  background: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 8px;
  font-family: "Inter";
}
