/* Input */
.react-date-range-picker {
    width: 100%;
}

.react-date-range-picker .react-daterange-picker__wrapper {
    height: 60px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #CCD3E0;
    color: #09093E;
    padding-left: 12px;
    padding-right: 12px;
    background: white;
}

.react-date-range-picker .react-daterange-picker__wrapper input {   
    color: #09093E;
}

.react-daterange-picker__clear-button {
    display: none;
}

.react-daterange-picker__calendar {
    margin-top: 8px;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
}

/* Calendar Popover */
.react-daterange-picker__calendar .react-calendar {
    border: none;
    padding: 10px;
    font-family: "Inter", sans-serif;
}

.react-calendar__tile--active {
    background: #3158F2;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #0d37da;
}

.react-calendar__navigation__label {
    font-weight: bold;
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
    font-size: 26px;
    color: #3158F2;
}