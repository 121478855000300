/* customToastify.css */

.Toastify__toast {
  background-color: #29315d !important; /* theme.colors.background */
  color: #ffffff !important; /* theme.colors.darkBlue */
  font-family: "Inter", sans-serif !important; /* theme.fontFamily */
  border-radius: 8px !important; /* theme.radii[0] */
  font-size: 14px;
  /* margin-left: 65px; */
  /* width: 400px; */
}

.Toastify__toast--info {
  background-color: #29315d !important; /* theme.colors.blue.primary */
}

.Toastify__toast--success {
  background-color: #29315d !important; /* theme.colors.green.primary */
}
.Toastify__toast--success .Toastify__toast-body svg {
  fill: #40da9e;
}

.Toastify__toast--warning {
  background-color: #29315d !important; /* theme.colors.orange[100] */
}

.Toastify__toast--error {
  background-color: #29315d !important; /* theme.colors.red.primary */
}

.Toastify__progress-bar {
  background: #29315d !important; /* theme.colors.blue.primaryEmphasis */
}

.Toastify__close-button {
  align-self: center;
  margin-right: 12px;
  color: #ffffff;
}
