/* Ensure the overlay inherits the background color correctly */
.slide-pane__overlay.overlay-after-open {
  background-color: inherit;
}

/* Customize the slide pane transitions */
.slide-pane {
  margin-top: 89px; /* TOPBAR_HEIGHT */
  box-shadow: 0px 5px 9px rgb(0 0 0 / 12%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(100%);
  opacity: 0;
}

/* Ensure the slide pane content does not overflow */
.slide-pane__content {
  padding: 0;
  overflow-y: hidden;
}

/* Customize the slider overlay transitions */
.slider-overlay {
  transition: opacity 0.3s ease-in-out;
}

/* Open state transitions */
.slide-pane.open {
  transform: translateX(0);
  opacity: 1;
}

/* Close state transitions */
.slide-pane.close {
  transform: translateX(100%);
  opacity: 0;
}
