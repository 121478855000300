/* Input */
.react-date-picker {
  width: 100%;
}
.react-date-picker-error {
  border: 2px solid #f75e6e;
  border-radius: 8px;
}

.react-date-picker .react-date-picker__wrapper {
  height: 60px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #ccd3e0;
  color: #09093e;
  padding-left: 12px;
  padding-right: 12px;
  background: white;
}

.react-date-picker .react-date-picker__wrapper input {
  color: #09093e;
}

.react-date-picker__clear-button {
  display: none;
}

.react-date-picker__calendar {
  margin-top: 8px;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

/* Calendar Popover */
.react-date-picker__calendar .react-calendar {
  border: none;
  padding: 10px;
  font-family: "Inter", sans-serif;
}

.react-calendar__tile--active {
  background: #3158f2;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0d37da;
}

.react-calendar__navigation__label {
  font-weight: bold;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 26px;
  color: #3158f2;
}
